.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.RowActions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.DropDownItem {
  display: flex;
  align-items: center;
  gap: 8px;
}
.DropDownText {
  font-size: 0.875rem;
}
