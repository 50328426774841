.Container {
  display: flex;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  flex-direction: column;
}
.Filter {
  padding: 16px;
  max-width: 100%;
  max-height: 100%;
}

.UsersContainer {
  flex-grow: 5;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding-right: 1em;
  height: 100%;
  max-height: 100%;
  padding-bottom: 1em;
}
.UsersList {
  display: flex;
  flex-direction: column;
  flex-grow: 1.15;
  flex-basis: 0;
  padding-right: 1em;
  padding-left: 1em;
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
}
.List {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.ListWrapper {
  border-radius: 5px;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}
.UsersListWrapper {
  height: 100%;
  flex-grow: 1;
  border-radius: 5px;
}
.UserContainer {
  flex-basis: 0;
  flex-grow: 5.5;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: 1px solid;
}
.UserDataContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.UserInfoContainer {
  display: flex;
  flex-direction: row;
  padding: 0px 16px 16px 16px;
  align-items: center;
  justify-content: space-between;
}
.AllInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.UserContainerDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 16px 16px 12px 16px;
  border-radius: 5px;
}
.UserAvatarContainer {
  display: flex;
  align-items: center;
}
.AvatarContainer {
  position: relative;
  display: flex;
  height: min-content;
  padding-right: 12px;
  cursor: pointer;
}

.StatusContainer {
  border-radius: 50%;
  width: 1.1rem;
  height: 1.1rem;
  position: absolute;
  bottom: 0.1rem;
  right: 0.6rem;
  border: 2.5px solid;
}
.NameContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.NameDiv {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.MoreIcon {
  margin-left: 12px;
  cursor: pointer;
}

.UserInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 15;
  flex-basis: 0;
  padding-left: 12px;
  width: 25rem;
}
.UserName {
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}

.CountryDiv {
  display: flex;
  align-items: center;
  gap: 2px;
  padding-bottom: 8px;
}

.InfoText {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Item {
  display: flex;
  flex-direction: row;
  align-self: start;
  justify-content: space-between;
  padding-bottom: 4px;
  width: 95%;
}
.LabelTitle {
  flex: 1;
  display: flex;
  align-items: center;
}

.LabelValue {
  flex: 3;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
}

.UserInfoRightContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 210px;
  padding-bottom: 4px;
}
.WalletContainerTitle {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding-right: 14px;
  padding-bottom: 4px;
}
.ReactFlagsSelect {
  border: none !important;
  padding: 0px !important;
}
.WalletContainerTitleText {
  font-size: 16px;
  font-weight: 600;
}
.CreatedAt {
  text-align: center;
  margin-bottom: 0.5rem;
  align-items: center;
  display: flex;
  align-self: flex-end;
}

.InfoTextPhone {
  align-items: center;
  display: flex;
  padding-bottom: 0.7em;
  width: fit-content;
}
.PhoneIcon {
  cursor: pointer;
  border: 1px solid !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  margin-left: 4px !important;
  font-size: 18px !important;
  padding: 2px !important;
}
.ChargingTokensContainer {
  padding: 16px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: -54px;
}
.PaymentTabs {
  padding: 16px;
  padding-bottom: 0px;
  padding-top: 0px;
  position: relative;
  z-index: 10;
  width: 50%;
}
.TransactionsContainer {
  padding: 16px;
  padding-bottom: 0px;
  padding-top: 0px;
  display: flex;
  flex-basis: 0;
  flex-shrink: 1;
  flex-direction: column;
  max-height: initial;
}
.TranactionsFilter {
  flex-shrink: 1;
  max-width: 100%;
}
.Tables {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
}
.Transactions {
  padding: 16px;
  padding-top: 8px;
  height: 100%;
}

.UserActionsContainer {
  flex-grow: 1;
  margin-top: 1em;
  margin-bottom: 1em;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.UserActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-basis: 0;
  flex-grow: 10;
  padding-left: 1em;
  padding-right: 1em;
  height: 100%;
  text-align: center;
}

.ActionButtons {
  flex-grow: 6;
}
.ButtonWrapper {
  padding-bottom: 1em;
}
.FormContainer {
  padding-bottom: 1em;
  width: inherit;
  max-width: inherit;
}
.Wallet {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 5px;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 210px;
  width: 100%;
  margin: 0 auto;
}
.WalletTitle {
  font-size: 16px;
  font-weight: bold;
}
.WalletContainer {
  display: flex;
  width: 100%;
}
.WalletItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 16px;
  width: 100%;
}
.Amount {
  font-size: 14px;
  font-weight: bold;
}
.PointsItemGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.Divider {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  padding-right: 16px;
}

.DeactivateButton {
  margin-bottom: 1em;
}
.HeaderText {
  font-weight: bold;
  font-size: 1.333rem;
  line-height: 1.9375rem;
  margin-bottom: 0.7em;
}

.ActionsTitle {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding-top: 0.5em;
  margin-bottom: 1.5em;
}
.ButtonClass {
  height: 3.5em;
}

.TextField {
  outline: 1px;
}
.CurrencyText {
  font-size: 1.5rem;
  padding-left: 0.5em;
}

.EditPhoneModalButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.ButtonPhoneEditModal {
  width: 8em;
}

.PaymentSessions {
  padding: 16px;
  padding-top: 8px;
  height: 100%;
}

.PointsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.UsersWrapper {
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
}

.Info {
  font-size: 10px !important;
}
.InfoDiv {
  margin-left: 4px;
  display: flex;
  border: 1px solid;
  padding: 1px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.countryName {
  font-size: 12px;
}
.LocationIcon {
  font-size: 1rem !important;
}
.WalletText {
  font-size: 0.875rem;
  margin-right: 1.5rem;
  min-width: 2.875rem;
}
.AdjustWallet {
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem;
  width: 100%;
}
.AdjustWalletItem {
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: 0.5rem;
  width: 100%;
}
.DetailWallet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.DetailWalletModified {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 2.5rem;
  align-items: 'center';
}
.DetailTextWallet {
  font-size: 0.875rem;
}
.LabelTitleWallet {
  flex: 2;
  display: flex;
  align-items: center;
}

.LabelValueWallet {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: left;
  width: 20%;
}
.ButtonOptions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ActionButton {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  align-items: center;
}
.DividerWallet {
  margin: 0.5rem 0 !important;
}
.ActionWidth {
  width: 110px;
  font-size: 0.875 !important;
}
.WalletModal {
  padding: 1.5rem !important;
}
.NoFilterMatch {
  padding: 1em;
  display: flex;
  justify-content: center;
  height: 100%;
}
.TextPhoneFiled {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  width: 100%;
}
.TablesContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.contentClassName {
  overflow-y: unset !important;
}
.ScrollParent {
  scroll-snap-type: y mandatory !important;
}
.LiveLogs {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.RefreshIcon {
  align-self: center;
  cursor: pointer;
  display: flex;
}
.RefreshSvg {
  margin-right: 4px;
}
.RefreshText {
  align-self: flex-start;
  text-align: right;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  font-weight: 600;
}
.RefreshTextDate {
  text-align: center;
  font-size: 0.75rem;
  margin-right: 0.5rem;
  margin-top: 0;
}
.TabsContainer {
  display: flex;
  justify-content: space-between;
  scroll-snap-align: start !important;
}
.AdminActions {
  padding: 1rem;
  padding-top: 0.5rem;
  height: 100%;
  padding-left: 1.625rem;
}
.LastUpdatedSkeleton {
  width: 6.25rem;
}
.RefreshIconTextSkeleton {
  display: flex;
  justify-content: center;
}
.RefreshIconSkeleton {
  margin-right: 4px;
  align-self: center;
}
.RefreshTextDateSkeleton {
  margin-right: 0.5rem;
  width: 8rem;
}
