.Wrapper {
  display: flex;
  justify-content: center;
}

.BubbleContainer {
  position: absolute;
  height: 3.125rem;
  width: 18.125rem;
  border-radius: 0.5rem;
  display: flex;
  margin-left: 1.75rem;
  top: 56px;
}

.BubbleContainerArabic {
  position: absolute;
  height: 3.125rem;
  width: 18.125rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 1.75rem;
  top: 56px;
}

.IphoneImg {
  width: 21.875rem;
  height: 7.1875rem;
}

.Logo {
  width: 1.5625rem;
  height: 0.875rem;
}

.LogoContainer {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.TitleContainer {
  width: 14.0625rem;
  margin-top: 0.4rem;
}

.TitleText {
  font-weight: bold;
  font-size: 0.7rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ArabicText {
  text-align: right;
  direction: rtl;
}

.SubtitleContainer {
  width: 14.0625rem;
}

.SubtitleText {
  font-size: 0.7rem;
  line-height: 1.1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.TextContainer {
  display: flex;
  flex-direction: column;
}
