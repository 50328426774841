.SideNavBarWrappingDiv {
  height: 100vh;
  display: flex;
}
.SideNavBarWrapping {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.HeaderBar {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}
.IconImg {
  width: 48px;
  height: 20px;
  margin-left: 4px;
}
.NameIconImg {
  max-width: 120px;
  max-height: 24px;
}

.ArrowDiv {
  width: 0px;
  margin-top: 30px;
  position: relative !important;
  left: -10px;
}
.ArrowButton {
  width: 20px;
  height: 20px;
  position: absolute !important;
  border-radius: 50% !important;
}
.ArrowIcon {
  font-size: 20px;
}
.UserDiv {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}
.LogoutDiv {
  justify-self: flex-end;
  width: 24px;
  height: 24px;
}
.UserContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}
.Drawerheader {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}
.DrawerheaderBottom {
  cursor: pointer !important;
}
