.Input {
  height: 0;
  width: 0;
  visibility: hidden;
}

.Label {
  cursor: pointer;
  display: block;
  border-radius: 100px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

.transitionWidthBackground {
  -webkit-transition: width 0.15s, background-color 0.15s;
  -moz-transition: width 0.15s, background-color 0.15s;
  -o-transition: width 0.15s, background-color 0.15s;
  transition: width 0.15s, background-color 0.15s;
}

.transitionWidth {
  -webkit-transition: width 0.15s;
  -moz-transition: width 0.15s;
  -o-transition: width 0.15s;
  transition: width 0.15s;
}

.Label:after {
  content: '';
  position: absolute;
  border-radius: 90px;
  transition: 0.15s;
  align-self: center;
}
.Input:checked + label:after {
  transform: translateX(-100%);
}

.IconContainer {
  display: flex;
}
.IconTextContainer {
  position: absolute;
  z-index: 10;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
}

.initialUncheckedVisible {
  opacity: 1;
}

.Container {
  display: flex;
  position: relative;
  overflow: hidden;
}
