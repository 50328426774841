.Container {
  display: flex;
  min-height: 42px;
  position: relative;
  align-items: center;
  padding-right: 10px;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}

.Container:hover {
  opacity: 0.5;
}

.Text {
  font-size: 0.875rem;
  font-weight: 600;
}

.PopperContainer {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border-style: solid;
}

.ButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  margin-bottom: 16px;
}

.SelectPicker {
  border-radius: 5px;
}

.Input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}

.TextInput {
  width: 80px !important;
}

.Unit {
  font-size: 0.8rem;
}

.NumberPicker {
  margin-left: 16px;
}
