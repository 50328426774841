.TitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.DialogTitle{
  padding: 0 !important;
  margin: 0 !important;
}
.DialogContent{
  padding: 0 !important;
  margin: 0 !important;
}

.Title {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
}

.ExitIcon {
  cursor: pointer;
}
