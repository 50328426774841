.ChartContainer {
  position: relative;
}

.BlurBackground {
  filter: blur(5px);
  pointer-events: none;
}
.Title {
  font-size: 16px;
  margin: 8px;
}
