.CloseIconContainer {
  position: absolute;
  top: 2.5rem;
  right: 8rem;
}

.CloseIcon {
  width: '20px';
  height: '20px';
  cursor: pointer;
}

.DividerWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.DividerContainer {
  width: 45%;
  align-self: center;
}

.DividerText {
  width: 10%;
  text-align: center;
  font-weight: 600;
}

.DropZone {
  height: 15rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.DropZoneSelected {
  height: 10rem;
  margin-bottom: 1.5rem;
  margin-top: 0rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: margin-top 0.5s;
}

.FadeOut {
  opacity: 0;
  max-height: 0px;
  margin: 0px;
  transition: max-height 0.5s, opacity 0.2s, margin 0.5s;
  pointer-events: none;
}
.InputWrappingDiv {
  margin-top: 0.75em;
}

.FieldsDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1em;
}
.InputWrappingDivCountry {
  margin-top: 0.75em;
  margin-right: 0.6em;
}
.FadeIn {
  width: 30rem;
  opacity: 1;
  max-height: 350px;
  transition: max-height 0.5s, opacity 1s;
}
.FadeOut {
  opacity: 0;
  max-height: 0px;
  margin: 0px;
  transition: max-height 0.5s, opacity 0.2s, margin 0.5s;
  pointer-events: none;
}
.DividerContainer {
  width: 45%;
  align-self: center;
}
.DividerText {
  width: 10%;
  text-align: center;
  font-weight: 600;
}

.CreateAppChargingTokenContainer {
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease;
}
.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 7rem;
}
.Submit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 1rem;
  width: 100%;
  align-items: center;
}
.InputWrappingCenterDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-left: 7.5rem;
}
.LabelsCenter {
  margin-right: 8.7rem;
}
.SubmitButton {
  width: 7.5em;
}
