.Table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.LeftTableCell {
  display: table-cell;
  padding: 12px 12px;
  text-align: left;
  height: 48px;
  flex-grow: 1;
  flex-basis: 0;
}
.RightTableCell {
  display: table-cell;
  padding: 12px 12px;
  text-align: right;
  height: 48px;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.LeftTableHeader {
  display: table-cell;
  padding: 12px 18px;
  text-align: left;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.RightTableHeader {
  display: table-cell;
  padding: 12px;
  margin-right: 4px;
  text-align: right;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.UserDetails {
  display: flex;
  align-items: center;
}
.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.Avatar {
  padding: 16px;
}

.TableInfo {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 110px;
}
.Title {
  font-size: 16px;
}

.NameText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
