body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rs-picker-menu {
  z-index: 1500 !important;
}
.rs-toast-container-top-center,
.rs-toast-container-top-start,
.rs-toast-container-top-end {
  z-index: 10000 !important;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  background-color: transparent;
}

.MuiTableContainer-root::-webkit-scrollbar {
  right: 0;
  width: 0px;
  height: 0px;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  width: 0px;
  height: 0px;
  visibility: hidden;
}
.os-padding {
  display: inline !important;
  height: 100% !important;
  width: 100% !important;
}
.os-viewport {
  width: 100% !important;
}
[data-overlayscrollbars~='host'],
.os-padding {
  display: inline !important;
}
.os-scrollbar.os-scrollbar-vertical.os-scrollbar-cornerless,
.os-scrollbar.os-scrollbar-vertical.os-scrollbar-cornerless.os-scrollbar-rtl {
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1000;
}
