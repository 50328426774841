.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NameContainer {
  display: flex;
  flex-direction: row;
  flex: 2.5;
  flex-basis: 0;
}

.SubscriptionNameContainer {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 8px;
  padding-right: 12px;
  z-index: 0;
}

.TariffName {
  display: flex;
  align-items: center;
  border-radius: 0px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  padding-left: 12px;
  padding-right: 8px;
  margin-left: -8px;
}

.NameText {
  font-size: 1rem;
}

.FieldContainer {
  display: flex;
  flex: 1;
  flex-basis: 0;
  height: 30px;
  align-items: center;
}

.EnergyConsumptionContainer {
  display: flex;
  flex: 1.5;
  flex-basis: 0;
}

.IconContainer {
  display: flex;
  flex: 0.25;
  justify-content: center;
  cursor: pointer;
}

.InfoIcon {
  align-self: center;
  margin-left: 0.5rem;
  width: 18px !important;
}

.TooltipContainer {
  display: flex;
  align-items: center;
}

.ToolTipWrapper {
  display: flex;
  padding: 0.25rem;
  flex-direction: column;
}

.Icon {
  font-size: 18px !important;
}

.RowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.LeftColumnContainer {
  display: flex;
  width: 5rem;
}

.ToolTipTitleText {
  font-size: 14px;
  font-weight: 500;
}

.ToolTipSubtitleText {
  font-size: 14px;
  font-weight: 400;
}
