.container {
  border-radius: 4px !important;
  display: flex;
  height: 3.1rem;
}
.Circular {
  width: 1.25rem;
  height: 1.75rem;
  border-radius: 50%;
  align-self: center;
  margin-left: 0.75rem;
}
.text {
  width: 9rem;
  height: 1.25rem;
  align-self: center;
  margin-left: 0.75rem;
}
