.CreateAppChargingTokenContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.CreateRolePermission {
  width: 40em;
  margin-top: 1em;
}
.InputWrappingDiv {
  margin-top: 0.75em;
}
.SaveButton {
  margin-top: 1em;
  margin-left: 32.5em;
  width: 7.5em;
}
.FieldsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
}
.InputWrappingDivCountry {
  margin-top: 0.75em;
  margin-right: 0.6em;
}
