.TextWrapper {
  display: flex;
  align-items: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  height: 2.5rem;
  display: inline;
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.container:hover {
  opacity: 0.5;
}

.Text {
  font-size: 0.875rem;
  font-weight: 600;
}
.DateRange {
  opacity: 0;
}
