.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.Bounds {
  display: flex;
  flex-direction: column;
}
.CountryItem {
  display: flex;
  gap: 0.5rem;
}
.FlagItem {
  font-size: 1.25rem;
}
.RowActions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ReactFlagsSelect {
  border: none !important;
  padding: 0px !important;
}
.DropDownItem {
  display: flex;
  align-items: center;
  gap: 8px;
}
.DropDownText {
  font-size: 0.875rem;
}
