.PermessionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3em;
}
.PermessionContainer:hover {
  background-color: #27a3ac1a;
  height: 3em;
}

.PermissionsDiv {
  flex-grow: 1;
}

.IFToggleDiv {
  flex-shrink: 0;
  margin-right: 8px;
}
.PermissionText {
  font-size: 14px;
  margin-left: 8px;
}
