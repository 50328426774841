.ScrollBarContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  gap: 1rem;
}
.skeletonCard {
  gap: 1rem;
}
.noDesignsFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}
