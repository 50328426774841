.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}
.TotalDiv {
  margin-left: 16px;
  font-size: 16px;
}
.Total {
  font-size: 16px;
  font-weight: 600;
}

.LegendContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 16px;
  width: 100%;
  margin-top: 16px;
}

.ChartContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  width: 50%;
  height: 100%;
}
