.Container {
  display: flex;
  height: 100%;
  overflow-y: hidden;
  flex-direction: column;
  padding-bottom: 0.25rem;
  padding-top: 1.25rem;
}

.Filter {
  padding: 1rem;
  padding-top: 0px;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.VouchersContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.TitleHeadersContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.WrapperContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 1rem;
}

.Container {
  display: flex;
  flex-direction: row;
}

.Container2 {
  background-color: white;
  width: 97.5%;
  height: 100%;
  align-self: center;
}

.HeaderButtonContainer {
  height: 100%;
  justify-content: flex-end;
  padding: 1.5rem;
}

.CreateButton {
  width: 8em;
  align-self: flex-end;
}

.TopContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border: 1px solid;
  border-radius: 5px;
}

.UserDataContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.FieldText {
  display: flex;
  flex-grow: 0.2;
  flex-basis: 0;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.Extra {
  display: flex;
  flex-grow: 0.5;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.TitleContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
}
.TitleText {
  font-weight: 600;
  font-size: 1rem;
}
.VouchersCount {
  display: flex;
  height: 1.5rem;
  min-width: 1.5rem;
  padding: 4px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
.TitleDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}
.Tables {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}
.ScrollParent {
  scroll-snap-type: y mandatory !important;
}
.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.DeleteIcon {
  font-size: 1rem;
}
.RowActions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
