.Container {
  width: inherit;
  height: 5em;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 1em;
  cursor: pointer;
}

.UserDetailsContainer {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  flex-grow: 5;
  width: 100%;
}
.Avatar {
  margin-right: 0.75rem;
  position: relative;
  display: flex;
  height: min-content;
}
.LastSeenItem {
  flex-grow: 1;
  align-self: flex-start;
  text-align: end;
  padding-right: 1em;
  padding-top: 0.7em;
}
.UserNameText {
  font-size: 16px;
  padding-bottom: 0.5em;
}

.LastSeenText {
  font-size: 0.75rem;
  line-height: 0.5rem;
}

.Phonetext {
  font-size: 0.75rem;
  margin-top: -0.5em;
}
.StatusContainer {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  position: absolute;
  bottom: -0.1rem;
  right: -0.2rem;
  border: 2.5px solid;
}
