.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.containerSES {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.containerSE {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.image {
  width: 48px;
  height: 48px;
  align-self: center;
}
.title {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 1.25rem;
  align-items: center;
  text-align: center;
}
.subTitle {
  margin-top: 0rem;
  font-size: 0.875rem;
  align-items: center;
  text-align: center;
}
.titleSE {
  margin-top: 1.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 25px;
  align-items: center;
  text-align: center;
}
.textArrow {
  margin-right: 1.25rem;
  display: flex;
  justify-content: right;
}
.textArrowHidden {
  opacity: 0;
  margin-right: 1.25rem;
  display: flex;
  justify-content: right;
}
.twistedArrow {
  padding-bottom: 2rem;
  padding-left: 0.5rem;
}

.addStationSite {
  font-weight: 600;
  font-size: 1rem;
  line-height: 25px;
  align-items: center;
  text-align: center;
  align-self: center;
}
.imagetextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.imageSES {
  width: 6rem;
  height: 6rem;
  align-self: center;
}

.titleSES {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 25px;
  align-items: center;
  text-align: center;
}

.CardsImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.CardsImage {
  width: 5rem;
  height: 5rem;
}

.titleNC {
  margin-top: 1.5rem;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 25px;
  align-items: center;
  text-align: center;
}
.buttonNC {
  margin-top: 1.5rem;
  align-self: center;
}
.noLogsTitle {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
}
.TryToRefresh {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.ImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.TitleNoSessions {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
}
.ContainerNoSessions {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}
.SessionsImage {
  width: 3rem;
  height: 3rem;
}
