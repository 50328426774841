.plugicon {
  width: 29px;
  height: 29px;
}

.Data {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 70%;
}

.Title {
  font-size: 16px;
}

.Count {
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0 0;
}
