.container {
  display: flex;
}
.tab {
  width: 7rem;
  height: 3rem;
  display: flex;
  border-bottom: none !important;
}
.text {
  width: 5.5rem;
  margin: auto;
  align-self: center;
}
