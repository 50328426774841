.CountContainer {
  display: flex;
  height: 1.5rem;
  min-width: 1.5rem;
  padding: 4px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
.Count {
  font-size: 12px;
}
