.FormWrappingDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.5em;
  width: 25rem;
}

.InputWrappingDiv {
  margin-top: 0.75em;
}

.Input {
  width: 18rem;
}
.ButtonDiv {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.ButtonWrappingDiv {
  margin-top: 1rem;
  width: 9rem;
}

.ErrorStyling {
  height: 0.6em;
  font-size: 0.7rem;
}

.LoadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReactFlagsSelect {
  display: flex !important;
  justify-content: space-between !important ;
}
.LoadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
