.RoleDetailsContainer {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.RoleDetails {
  flex: 1;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.RoleDetailsDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4em;
}

.RoleHeaders {
  font-size: 16px;
  font-weight: 500;
  flex-shrink: 0;
  width: 100px;
}

.RoleValues {
  font-size: 14px;
  flex-grow: 1;
  padding-right: 2em;
}
.PermissionsDiv {
  margin-left: 1em;
  margin-top: 1em;
}
.PermissionsDivDivider {
  flex: 1;
  border-left: 1px solid #e0e0e0;
}
.EditButton {
  position: absolute;
  align-self: flex-end;
  right: 5px;
  width: 7.5em;
}
.saveButton {
  position: absolute;
  align-self: flex-end;
  right: 9em;
  width: 7.5em;
}
.CancelButton {
  position: absolute;
  align-self: flex-end;
  right: 0.4em;
  width: 7.5em;
}
.FieldText {
  font-size: 14px !important;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: -2em;
  margin-top: -3px;
  margin-bottom: -2.5px;
}
.EditRoleButtonsDiv {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-top: 1em;
}

.PermissionsSkeleton {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 1em;
  margin-top: 1em;
}
.PermissionsKeletonDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11.7em;
}
