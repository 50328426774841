.SkeletonLineDiv {
  display: flex;
  width: 1.75rem;
  align-items: center;
  justify-content: center;
}
.SkeletonLine {
  width: 2px;
  height: 1.1875rem;
  margin-bottom: 0.5rem;
}
.NoRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
}
.timeline {
  padding: 0px !important;
}
.AdminActionList {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.VirtuosoDiv {
  width: 100% !important;
  height: 100% !important;
}
.TimeLineDot {
  box-shadow: none !important;
  border-width: 6px !important;
  padding: 0px !important;
}
.TimeLineContent {
  padding: 12px 16px !important;
}
.Icons {
  width: 16px !important;
  height: 16px !important;
}
.Popover {
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.DetailItem {
  display: flex;
}
.LabelTitle {
  align-items: center;
  display: flex;
  justify-self: start;
  min-width: 12rem;
}
.LabelValue {
  align-items: center;
  display: flex;
  justify-self: end;
}
.LabelValueObject {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-self: end;
}
.LabelTitleObject {
  align-items: flex-start;
  display: flex;
  justify-self: start;
  min-width: 12rem;
}
.FlagItemAction {
  font-size: 0.875rem;
}
.ReportIconDiv {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: flex-start;
  align-items: center;
}
.TextDiv {
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
}
.LoadingMore {
  position: relative;
  top: -1.25rem;
}
.CountryName {
  font-size: 0.875rem;
}
.DateCountryDiv {
  display: flex;
  gap: 4px;
  align-items: center;
}
.DateName {
  font-size: 0.75rem;
}
