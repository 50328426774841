.ChargingSpecsAdd {
  margin-top: 1rem;
  width: 100%;
}

.EmptyContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ListWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.SubscriptionListWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  overflow: hidden;
}

.SubscriptionListWrapperEditing {
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
}

.HeaderRowContainer {
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
}

.HeaderNameContainer {
  display: flex;
  flex: 2.5;
}

.HeaderContainer {
  display: flex;
  flex: 1;
}

.HeaderText {
  font-size: 14px;
  font-weight: 500;
}
