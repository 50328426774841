.Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.UserPageTabTitle {
  font-size: 14px;
  text-transform: none;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
