.IconSize {
  height: 24px;
  width: 24px;
}

.IconBigger {
  animation: scaleUp 300ms ease-in-out;
  transform: scale(1);
}

.IconSmaller {
  animation: scaleDown 300ms ease-in-out;
  transform: scale(0.66);
}

@keyframes scaleDown {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.66);
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.66);
  }
  to {
    transform: scale(1);
  }
}

.loading {
  animation: fadeInFromNone 0.5s;
  -webkit-animation: fadeInFromNone 0.5s;
  -moz-animation: fadeInFromNone 0.5s;
  -o-animation: fadeInFromNone 0.5s;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
