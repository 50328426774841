.AdminItemDiv {
  display: flex;
  align-items: center;
}

.AdminItemDetails {
  margin-left: 1em;
}
.AdminName {
  font-size: 14px;
}
.AdminEmail {
  font-size: 12px;
}
