.CardButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.4rem;
  padding: 0 1rem;
}
.CardIcons {
  display: flex;
  flex-direction: row;
  margin-left: 5.5rem;
  gap: 1rem;
}
