.Transaction {
  margin: 0.5em;
  width: auto;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
}

.LoadingIndicatorWrapper {
  display: flex;
  justify-content: center;
  padding-top: 6em;
}
.EmptySet {
  margin-top: 1.25rem;
  text-align: center;
}
.NoSessions {
  margin-top: 0.5rem;
}
.TransactionName {
  display: flex;
  align-items: center;
}
.TransactionDetails {
  display: flex;
  padding-left: 8px;
}
.Line {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
}
.Details {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-top: 16px;
  width: 100%;
  gap: 12px;
}
.ChargingSessionDetails {
  display: flex;
  flex-direction: column;
}
.TransactionTitle {
  font-size: 14px;
  font-weight: 500;
}
.AllDetails {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}
.Detail {
  display: flex;
  flex-direction: row;
  align-self: start;
  justify-content: space-between;
  width: 48%;
}
.DetailText {
  font-size: 14px;
}
.LabelTitle {
  flex: 1;
  display: flex;
  align-items: center;
}

.LabelValue {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: left;
}
.ColumnTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.ReverseButtonContainer {
  display: flex;
  align-self: flex-end;
}
.Status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  padding: 4px 4px;
}
.ExpandMoreIcon {
  cursor: pointer;
  pointer-events: none;
  background-color: transparent;
  border: none;
  height: 24px;
  width: 24px;
  margin: -5px;
}
.ExpandMoreIcon:hover {
  background-color: none;
}
.tables {
  height: 100%;
  max-height: 100%;
}
.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
