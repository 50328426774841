.NotificationDetails {
  display: flex;
  padding-left: 4px;
  width: 100%;
}
.Line {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  justify-content: center;
}
.Divider {
  height: 100%;
  margin: 0;
}
.Circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: calc(50%);
}
.Details {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-top: 16px;
  width: 100%;
  gap: 12px;
}
.NotificationsDetailsPanel {
  display: flex;
  flex-direction: column;
}
.NotificationTitle {
  font-size: 14px;
  font-weight: 500;
}
.AllDetails {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}
.Detail {
  display: flex;
  flex-direction: row;
  align-self: start;
  justify-content: space-between;
  width: 48%;
}
.DetailText {
  font-size: 14px;
}
.LabelTitle {
  flex: 1;
  display: flex;
  align-items: center;
}

.LabelValue {
  flex: 1;
  display: flex;
  align-items: center;
  text-align: left;
}
.ColumnTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ButtonContainer {
  display: flex;
  align-self: flex-end;
  gap: 8px;
}
.Button {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 9em;
  height: 3.5em;
}
