.WrapperContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 1rem;
}
.TopContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border: 1px solid;
  border-radius: 5px;
}
.UserDataContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}
.UserContainerDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 1rem 1rem 0.75rem 1rem;
  border-radius: 5px;
}
.UserAvatarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.AvatarContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  height: min-content;
  padding-right: 0.75rem;
}
.UserName {
  font-size: 1.375rem;
  font-weight: 600;
}
.ScrollParent {
  scroll-snap-type: y mandatory !important;
}
.UserInfoContainer {
  display: flex;
  flex-direction: row;
  padding: 0px 1rem 1rem 1rem;
  align-items: center;
  justify-content: space-between;
}
.AllInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.UserInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 15;
  flex-basis: 0;
  padding-left: 0.75rem;
  width: 25rem;
}
.InfoText {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Item {
  display: flex;
  flex-direction: row;
  align-self: start;
  justify-content: space-between;
  padding-bottom: 4px;
  width: 95%;
}
.LabelTitle {
  flex: 1;
  display: flex;
  align-items: center;
}

.LabelValue {
  flex: 3;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
}
.ButtonChangePasswordModal {
  width: 8rem;
}
.contentClassName {
  overflow-y: unset !important;
}
.CountryListDiv {
  padding-left: 1.75rem;
}
.CountryName {
  font-size: 0.875rem;
}
.CountryList {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.CountryItem {
  display: flex;
  gap: 0.5rem;
}
.LogoutDiv {
  display: flex;
  width: 7.5rem;
}
.ButtonsDiv {
  display: flex;
  gap: 0.5rem;
}
.EditIcon {
  cursor: pointer;
}
.ButtonPhoneEditModal {
  width: 8rem;
}
.contentClassName {
  overflow-y: unset !important;
}
.UserNameField {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  width: 100%;
}
.ActionWidth {
  width: 6.875rem;
  font-size: 0.875rem !important;
}
.FlagItem {
  font-size: 1.25rem;
}
.AdminActionsListDiv {
  padding-left: 1.75rem;
  padding-top: 1rem;
  height: 100%;
  max-height: 100%;
}
.DateName {
  font-size: 0.75rem;
}
.AdminActionList {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.Icons {
  width: 16px !important;
  height: 16px !important;
}
.Filter {
  padding-top: 8px;
  padding-right: 16px;
  max-width: 100%;
  max-height: 100%;
}
.AdminListWrapper {
  padding-left: 10px;
}
