.CountDiv {
  display: flex;
  height: 1.5rem;
  min-width: 1.5rem;
  padding: 4px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
}
.Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
}
.NoAdminsContainer {
  margin-left: 0.5em;
}
.AdminDetailsDiv {
  margin-top: 0.7em;
  gap: 4em;
}
.CountText {
  font-size: 12px;
}
.AdminListTitle {
  font-size: 16px;
  font-weight: 600;
}
.AdminsDetailsDivContainer {
  margin-top: 1em;
}
.AdminsContainerDiv {
  display: flex;
  flex-direction: column;
}
.NoAdminsAssignedText {
  font-size: 14px;
  margin-top: 1em;
}
