.Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.5em;
  width: 800px;
}

.FieldsWrapper {
  display: flex;
  flex-direction: column;
}

.TextFieldContainer {
  margin-top: 1.5em;
  transition: margin-top 0.75s;
}

.Input {
  width: 270px;
}

.SubmitButton {
  width: 8em;
}

.ButtonContainer {
  display: flex;
  margin-top: 1.5em;
  justify-content: flex-end;
}

.ErrorStyling {
  height: 0.6em;
  font-size: 0.7rem;
}

.Button {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 9em;
  height: 3.5em;
}

.ShowContainer {
  opacity: 1;
  height: 180px;
  /* width: 800px; */
  transition: opacity 0.5s, height 0.75s;
  margin-top: 1rem;
}

.HideContainer {
  opacity: 0;
  height: 0px;
  /* width: 56px; */
  transition: opacity 0.5s, height 0.75s;
}

.InputWrappingDivHidden {
  margin-top: 0rem;
  transition: margin-top 0.75s;
}

.HeadersContainer {
  display: flex;
  flex-direction: row;
  font-weight: 500;
}

.HeaderContainer {
  display: flex;
  flex: 1;
  flex-basis: 0;
}

.EnergyConsumptionContainer {
  display: flex;
  flex: 1.5;
  flex-basis: 0;
}

.HeaderText {
  font-weight: 500;
  font-size: 14px;
}

.SubscriptionsContainer {
  display: flex;
  flex: 2.5;
  flex-basis: 0;
}

.TariffContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.IconContainer {
  display: flex;
  flex: 0.25;
}
