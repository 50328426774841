.Button {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 9em;
  height: 3.5em;
}
.ModalTitle {
  font-weight: bold;
}
.AdminNameColumn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.EditRole {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -1.37rem;
}

.AdminTableIcons {
  display: flex;
}

.DeleteIcon {
  font-size: 1rem;
}
.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.DoneIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CustomBackGround {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.CustomBackGround:hover {
  background-color: rgb(0, 0, 0, 0.05);
}
