.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.PanelTitle {
  font-size: 1.25rem;
  font-weight: 600;
}
.PanelTitleDiv {
  padding: 1rem;
}

.InputTitle {
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: black;
}

.MiddlePanel {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 62rem;
  justify-content: center;
  margin-top: 14px;
}

.SendNotificationButton {
  width: 10em;
  height: 2.5em;
}

.Inputs {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
}

.DevicePreviewWrapper {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.BottomPanel {
  display: flex;
  flex-direction: row;
}

.ButtonWrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  margin-inline-start: auto;
}

.DevicePreviewTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.DevicePreviewText {
  font-weight: 500;
}

.DevicePreview {
  margin-top: 3rem;
}

.ArabicDevicePreview {
  margin-top: 6rem;
}

.LoadingLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.LoadingLogo {
  width: 20rem;
}

.ErrorContainer {
  height: 25px;
}
.MenuItemText {
  font-size: 14px;
}
.Skeleton {
  padding-top: 4px;
  padding-bottom: 4px;
}
.Date {
  width: '500px';
  padding-top: '4px';
  padding-bottom: '16px';
  height: '37px';
}
