.Button {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 9em;
  height: 3.5em;
}
.ModalTitle {
  font-weight: bold;
}
.AdminNameColumn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.EditRole {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -1.37rem;
}

.EditIcon {
  display: flex;
}

.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
