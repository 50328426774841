.PageContainer {
  display: flex;
}

.RightColumnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.ContentContainer {
  width: 100%;
  height: 100%;
}
