.TextContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: black !important;
  background-color: white !important;
}

.TextField {
  all: unset;
  flex-grow: 1;
  font-size: 0.875rem;
}

.TextInputWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  max-height: 2.5rem;
  overflow-y: hidden;
}

.TextInputWrapper:hover {
  overflow-y: auto;
}

.TextInputWrapper::-webkit-scrollbar {
  width: 12px;
}

.TextInputWrapper::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
  border: 3px solid white;
}

.Button {
  flex-shrink: 0;
  height: 2rem;
  width: 6rem;
  font-size: 1rem;
  margin-right: 1rem;
}

.InputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 1rem;
}

.FilterIcon {
  margin-left: 1rem;
  margin-right: 0.5rem;
  font-size: 1rem;
}

.accordion:focus-visible {
  background-color: white;
  outline: none;
}

.download {
  position: absolute;
  right: 1rem;
  align-self: center;
}

.FlashContainer {
  width: 0.9375rem;
  height: 0.9375rem;
  align-self: center;
}

.FlashLogoContainer {
  border-radius: 1.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.FlashLogoContainer:hover .FlashContainer {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.ChargingSpeedContainer {
  display: flex;
  flex-direction: row;
}

.ChargingSpeedChip {
  display: flex;
  flex-direction: row;
}

.FilterPlaceHolderText {
  flex-grow: 1;
}

.LoadingIndicator {
  margin-left: 0.5rem;
  margin-right: 1rem;
}

.SortContainer {
  margin-left: 1rem;
  margin-right: 0.3rem;
}
.CloseIcon {
  width: 14px !important;
  height: 14px !important;
  color: inherit !important;
}
.LabelText {
  display: inline;
}
