.InviteAdminContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  margin-top: 1rem;
}
.SaveButton {
  width: 7.5em;
  align-self: flex-end;
}
.InputWrappingDiv {
  margin-top: 0.75em;
}
