.Container {
  display: flex;
  justify-content: flex-start;
  padding: 8px;
  align-items: center;
  width: fit-content;
}
.IFTabTitle {
  font-size: 16px;
  text-transform: none;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.CountDiv {
  margin-left: 0.5em;
}
