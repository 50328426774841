.FormWrappingDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  border-radius: 0.5em;
  width: 25rem;
  overflow: hidden;
}

.InputWrappingDiv {
  margin-top: 0.75em;
}

.Input {
  width: 18em;
}
.ButtonDiv {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.ButtonWrappingDiv {
  margin-top: 2em;
  height: 2.5em;
  width: 9rem;
}

.ErrorStyling {
  height: 0.6em;
  font-size: 0.7rem;
}

.LoadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.FlagItem {
  font-size: 1.25rem;
}
.DropDownItem {
  display: flex;
  align-items: center;
  gap: 8px;
}
.DropDownText {
  font-size: 0.875rem;
}
