.Container {
  width: inherit;
  height: 5em;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 1em;
  cursor: pointer;
}
.Avatar {
  margin-right: 1em;
}

.UserDetailsContainer {
  justify-content: flex-start;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  flex-grow: 5;
}
.LastSeenItem {
  flex-grow: 1;
  align-self: flex-start;
  text-align: end;
  padding-right: 1em;
  padding-top: 0.7em;
  width: 20%;
}
