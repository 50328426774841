.FormWrappingDiv {
  display: flex;
  justify-content: center;
  margin-right: auto;
  padding-top: 1em;
  padding-bottom: 1.5em;
  margin-left: auto;
  border-radius: 0.5em;
}

.LogoWrappingDiv {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
}

.Logo {
  height: auto;
  width: 18em;
}

.InputWrappingDiv {
  margin-top: 1.5em;
}

.Input {
  width: 18em;
}

.ButtonWrappingDiv {
  margin-top: 2em;
  height: 2.5em;
  border: none;
}

.ForgotPassword {
  font-size: 0.6rem;
  margin-bottom: 0.2em;
}

.ForgotPasswordDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  cursor: pointer;
}

.ErrorStyling {
  height: 0.6em;
  font-size: 0.7rem;
}

.BackgroundDiv {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Container {
  display: flex;
  width: 880px;
  height: 600px;
  border-radius: 10px;
}
.WelcomeCard {
  flex: 2.1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.FormDiv {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.WelcomeTitle {
  font-size: 32px;
  font-weight: 600;
}
.WelcomeText {
  font-size: 16px;
}
.AcceptedText {
  display: flex;
  flex: 0.5;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 1.1rem;
}

.ClickHere {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.ClickHereText {
  margin-right: 4px;
}

.PinFieldContainer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ConfirmButton {
  display: flex;
  justify-content: center;
  width: 7.5rem;
  margin-left: 11.5rem;
}

.SubTitle {
  font-size: 1rem;
  margin-top: 0.5rem;
  text-align: center;
}

.InputFieldContainer {
  margin-top: 1.5rem;
}
