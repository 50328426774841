.UpdateOnRoleColumn {
  display: flex;
  justify-content: space-between;
  right: 4em;
  width: 100%;
}
.UpdateOnRoleTable {
  align-self: center;
  min-width: 9em;
}

.MoreInfo {
  cursor: pointer;
}
.Button {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 9em;
  height: 3.5em;
}
.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
