.WrapperContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 1rem;
}
.TopContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border: 1px solid;
  border-radius: 5px;
}

.UserDataContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}
.TabButtonDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  align-items: center;
}
.InnerContainer {
  padding: 16px;
}
.CreateButton {
  width: 8em;
  align-self: flex-end;
}
.Filter {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}
.Tables {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}
