.Buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.Title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.TitleText {
  font-size: 18px !important;
  padding: 16px !important;
}
