.Container {
  display: flex;
  height: 100%;
  overflow-y: hidden;
  flex-direction: column;
}

.Filter {
  padding: 1rem;
  max-width: 100%;
  max-height: 100%;
}

.LoadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.BottomContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  margin-top: 0rem;
  height: 100%;
  max-height: 100%;
  border-radius: 5px;
}

.TotalsContainer {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  padding-top: 0;
  width: 100%;
  justify-content: space-between;
  flex: 1;
}
.TotalsCard {
  flex-shrink: 0.3;
}

.TransactionsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.TitleHeadersContainer {
  display: flex;
  flex-direction: row;
}

.TitleContainer {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  padding: 1rem;
}

.TitleText {
  font-size: 1rem;
  font-weight: 600;
}

.TransactionListContainer {
  height: 100%;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Chart {
  margin-right: 16px;
  flex: 1.25;
}

.OuterContainer {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
