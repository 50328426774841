.CardHeader {
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DPContainer {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  flex-direction: row;
  display: flex;
  margin-top: 20px;
}
.Data {
  font-size: 30px;
  font-weight: 300;
}

.Percentage {
  font-size: 12px;
}
.Period {
  font-size: 12px;
  margin-top: -4px;
}
