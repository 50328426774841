.NoUsersFoundText {
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
}

.Container {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.NoUsersFoundContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 6.25rem;
}

.NoUsersFoundImage {
  width: 3em;
  height: 3em;
  display: flex;
  align-self: center;
  margin-top: 2em;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
}
.LoadingData {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.NoRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
}
.IconDiv {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}
.TextDiv {
  display: flex;
  flex-direction: column;
}
.ReportIconDiv {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
}
