.Filter {
  /* padding: 16px; */
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 16px;
}
.Container {
  padding: 16px;
  height: 100vh;
  width: 100%;
}
.TopContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 5px;
}
.GridContainer {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}
