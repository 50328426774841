.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;
  height: 2.5rem;
}
.ContainerDiv {
  display: flex;
  width: 100%;
  gap: 0.5rem;
}
.CircularSkeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 4.375rem;
}
.BodySkeleton {
  flex-grow: 1;
  height: 2.5rem;
  gap: 0.5rem;
}
