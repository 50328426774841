.InputTitle {
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: black;
}
.Inputs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  height: 80%;
  width: 250px;
}
.Skeleton {
  padding-top: 4px;
  padding-bottom: 4px;
}
.ErrorContainer {
  height: 25px;
}
.ButtonWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 62px;
  justify-content: space-between;
  align-items: 'center';
}
.Button {
  width: 100px;
  padding: 8px 4px 0px 0px;
}
.ReactFlagsSelect {
  display: flex !important;
  justify-content: space-between !important ;
}
.Builder {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 690px;
  overflow: hidden;
}

.Scrollable {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 500px;
}
.ButtonPlacement {
  display: flex;
  flex-direction: row;
  gap: '10px';
}
.QueryCount {
  margin-top: 16px;
  display: 'flex';
  align-items: 'center';
  gap: 8px;
}
