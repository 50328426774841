.PaymentSessionList {
  margin: 0.5em;
  padding-top: 1rem;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
}

.LoadingIndicatorWrapper {
  display: flex;
  justify-content: center;
  padding-top: 6em;
}
.EmptySet {
  margin-top: 1.25rem;
  text-align: center;
}
.NoSessions {
  margin-top: 0.5rem;
}
.ColumnTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.TransactionName {
  display: flex;
  align-items: center;
}
.Status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  padding: 4px 4px;
}
.MRT_Table {
  max-height: 600px;
  overflow-y: scroll;
}
.NoRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  padding: 2rem;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
}
.DetailsContainer {
  padding-top: 0.5rem;
  margin-left: 1rem;
}
.DetailPanel {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  width: 100%;
}
.ExpandMoreIcon {
  cursor: pointer;
  pointer-events: none;
  background-color: transparent;
  border: none;
  height: 24px;
  width: 24px;
  margin: -5px;
}
.ExpandMoreIcon:hover {
  background-color: none;
}
.tables {
  height: 100%;
  max-height: 100%;
}
.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
