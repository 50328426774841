@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url(../../Assets/Fonts/ProximaNova/ProximaNova_Regular.ttf)
      format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url(../../Assets/Fonts/ProximaNova/ProximaNova_Semi_Bold.ttf)
      format('truetype');
  font-weight: 500 600;
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url(../../Assets/Fonts/ProximaNova/ProximaNova_Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url(../../Assets/Fonts/ProximaNova/ProximaNova-Italic.ttf)
      format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'),
    url(../../Assets/Fonts/ProximaNova/ProximaNova-BoldItalic.ttf)
      format('truetype');
  font-weight: bold;
  font-style: italic;
}

.text {
  font-family: 'ProximaNova';
  margin: 0;
}
