.Button {
  font-size: 0.75rem;
  margin-right: 1em;
  width: 9em;
  height: 3.5em;
}

.TableIcons {
  display: flex;
}

.DeleteIcon {
  font-size: 1rem;
}
.CustomBackGround {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.CustomBackGround:hover {
  background-color: rgb(0, 0, 0, 0.04);
}
.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
