.Container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.InputWrappingDiv {
  display: flex;
  flex-direction: column;
}
.SaveButton {
  margin-top: 1em;
  align-self: center;
  width: 7.5em;
  align-self: flex-end;
}
.FieldsDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1em;
  gap: 1rem;
}
