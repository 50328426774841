.emptySet {
  margin-top: 1.25rem;
  text-align: center;
}

.deactivateWrapper {
  flex-grow: 0.5;
  flex-basis: 0;
  display: flex;
  justify-content: flex-start;
}
.deactivateContainer {
  width: 7.5rem;
  align-self: center;
}

.HeaderText {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.9375rem;
}
.TopToolBar {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
}
.TopToolBarFullScreen {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.NoRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  padding: 2rem;
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
}

.Toggle {
  display: flex;
}
.AddCardForm {
  display: flex;
  flex-direction: column;
}

.IconHover {
  display: flex;
  justify-self: flex-end;
}
.AddIcon {
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
  padding: 1px;
}
