.Container {
  justify-content: left;
  align-items: center;
}
.TitleText {
  float: 1;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}
.CardButtons {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.DeleteIcon {
  font-size: 1rem;
}
.CardIcons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-right: 0.5rem;
}
